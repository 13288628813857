import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SyncIcon from '@mui/icons-material/Sync'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Typography } from '@mui/material'
import { CircularProgressPercent } from 'components/_NEW/CircularProgressPercent/CircularProgressPercent'
import { StyledErrorBox, StyledReloadButton } from 'components/_NEW/UploadFile/style'
import { theme } from 'global/styles/theme'
import { FileChosen } from './StatusDDFileInputWrappers/FileChosen'
import { Initial } from './StatusDDFileInputWrappers/Initial'
import { IUploadStatus } from './UploadFileDrawer.def'

interface IDDFileInputWrapperProps {
  uploadStatus: IUploadStatus
  repeatFile(): void
}

export function DDFileInputWrapper({
  uploadStatus: { message, type = 'initial', progressPercent = 0, remainTime = '' },
  repeatFile,
}: IDDFileInputWrapperProps) {
  switch (type) {
    case 'error_upload_success':
      return (
        <StyledErrorBox>
          <WarningAmberOutlinedIcon
            sx={{
              width: '51px',
              height: '48px',
              mt: '30px',
            }}
          />
          <Typography variant="body2" sx={{ mt: '30px' }}>
            Файл Загружен. Произошла ошибка при отмене загрузки файла!
          </Typography>
          <StyledReloadButton startIcon={<SyncIcon />} onClick={repeatFile} disableRipple>
            загрузить еще раз
          </StyledReloadButton>
        </StyledErrorBox>
      )
    case 'error':
      return (
        <StyledErrorBox>
          <WarningAmberOutlinedIcon
            sx={{
              width: '51px',
              height: '48px',
              mt: '30px',
            }}
          />
          <Typography variant="body2" sx={{ mt: '30px' }}>
            Произошла ошибка при загрузке файла!
          </Typography>
        </StyledErrorBox>
      )
    case 'canceling':
      return (
        <Typography
          variant="body2"
          sx={{
            justifyContent: 'center',
            color: theme.palette.secondary.gray,
          }}>
          Идет процесс отмены загрузки...
        </Typography>
      )
    case 'canceled':
      return (
        <Typography variant="body2" sx={{ justifyContent: 'center', color: theme.palette.error.main }}>
          Загрузка отменена
        </Typography>
      )
    case 'initial':
      return <Initial />
    case 'file_chosen':
      return <FileChosen />
    case 'upload_progress':
      return <CircularProgressPercent thickness={3.6} value={progressPercent!} time={remainTime!} />
    case 'upload_success':
      return (
        <>
          <CheckCircleIcon sx={{ color: '#6FCCBC', fontSize: '48px' }} />
          <Typography mt={'20px'} variant="body2" align="center" sx={{ color: theme.palette.secondary.main }}>
            Успешно обработано!
          </Typography>
        </>
      )
    default:
      return <Initial />
  }
}
