import { validExcelFormats } from 'global/types/commos-def'
import { mixed, object } from 'yup'

const isValidExcelFile = (file: File) => {
  return validExcelFormats.includes(file.type)
}

export const uploadBdrValidationSchema = object({
  file: mixed()
    .required('Файл обязателен')
    .test('fileType', 'Неверный тип файла', (value) => value && isValidExcelFile(value)),
})
