import { api } from 'api/api'
import { ExcelUploadResponse } from 'global/types/commos-def'
import { UploadBdrFileRequest } from './bdr.types'

export const bdrApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBdrFileTemplate: build.query<string, void>({
      query: () => ({
        url: '/bdr/financier/articles/excel/template',
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
    uploadBdrFile: build.mutation<ExcelUploadResponse, UploadBdrFileRequest>({
      query: ({ body }) => {
        const formData = new FormData()
        formData.append('file', body.file)

        return {
          url: '/bdr/financier/articles/excel/upload',
          method: 'POST',
          body: formData,
        }
      },
    }),
  }),
})

export const { useLazyGetBdrFileTemplateQuery, useUploadBdrFileMutation } = bdrApi
