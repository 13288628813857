import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Download } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useLazyGetBdrFileTemplateQuery, useUploadBdrFileMutation } from 'api/bdr/bdr'
import { ConfirmDialog } from 'components/ConfirmDialog'
import { MyDrawer } from 'components/MyDrawer/MyDrawer'
import { errorUploadHandler } from 'global/api/api'
import { downloadFile } from 'global/utils/downloadFile'
import { Fragment, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormProvider, useForm } from 'react-hook-form'
import { BtnGroupWrapper } from './BtnGroupWrapper'
import { DDFileInput } from './DDFileInput'
import { DDFileInputWrapper } from './DDFileInputWrapper'
import { DefaultAlertText } from './DefaultAlertText'
import { IUploadDrawerProps, IUploadStatus } from './UploadFileDrawer.def'
import { DISABLE_FILE_INPUT_STATUSES, DISABLE_SUBMIT_BTN_STATUSES } from './UploadFileDrawer.service'
import { DragWrapper } from './UploadFileDrawer.styles'
import { uploadBdrValidationSchema } from './UploadFileDrawer.validation'
import { useSnackbar } from 'notistack'
import { isFetchError } from 'global/types/commos-def'

export const UploadFileDrawer = NiceModal.create<IUploadDrawerProps>(({ refresh, ...props }) => {
  const { visible, hide, resolve } = useModal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const methods = useForm({
    resolver: yupResolver(uploadBdrValidationSchema),
  })
  const {
    formState: { isDirty },
    setValue,
  } = methods

  const UPLOAD_STATUS_DEFAULT: IUploadStatus = {
    type: 'initial',
  }
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>(UPLOAD_STATUS_DEFAULT)

  const [getBdrFileTemplate] = useLazyGetBdrFileTemplateQuery()
  const [uploadBdrFile, uploadBdrFileResponse] = useUploadBdrFileMutation()

  useEffect(() => {
    if (!uploadBdrFileResponse.isSuccess) return

    const key = enqueueSnackbar('Файл успешно загружен', {
      variant: 'success',
      onClick: () => closeSnackbar(key),
    })

    resolve()
    closeDrawer()
  }, [uploadBdrFileResponse.isSuccess])

  useEffect(() => {
    if (!uploadBdrFileResponse.isError) return

    const key = enqueueSnackbar(
      isFetchError(uploadBdrFileResponse.error) ? uploadBdrFileResponse.error?.data : 'Ошибка при загрузке файла.',
      {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      },
    )
  }, [uploadBdrFileResponse.isError])

  const onDrop = (files: File[]) => {
    if (files?.length > 0) {
      setValue('file', files[0], { shouldDirty: true })
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    disabled: DISABLE_FILE_INPUT_STATUSES.includes(uploadStatus.type),
    onDrop,
    onDropRejected,
  })

  function onDropRejected() {
    const error = {
      message: 'Тип файла не является .xls или .xlsx ',
    }
    errorUploadHandler(error, 'upload')
  }

  const closeDrawerWithConfirmHandler = () => {
    isDirty ? NiceModal.show(ConfirmDialog, { onSuccess: closeDrawer }) : closeDrawer()
  }

  function closeDrawer() {
    hide()
    methods.reset()
    setUploadStatus(UPLOAD_STATUS_DEFAULT)
  }

  function repeatFile() {
    methods.reset({})
    setUploadStatus(UPLOAD_STATUS_DEFAULT)
  }

  function localCancelSendFile() {
    setUploadStatus((prevState) => ({
      ...prevState,
      type: 'canceling',
    }))
  }

  useEffect(() => {
    methods.getValues('file') &&
      setUploadStatus((prevState) => ({
        ...prevState,
        type: 'file_chosen',
      }))
  }, [methods.watch('file')])

  const getTemplateClickHandler = () => {
    getBdrFileTemplate().then((res) => {
      if (!res.data) return

      downloadFile(res.data, 'template.xlsx')
    })
  }

  const submitFormClickHandler = methods.handleSubmit((values) => {
    uploadBdrFile({ body: { file: values.file } })
  })

  return (
    <Fragment>
      <MyDrawer.Wrapper {...props} open={visible} onClose={closeDrawerWithConfirmHandler}>
        <FormProvider {...methods}>
          <MyDrawer.Title>Загрузить Excel</MyDrawer.Title>
          <MyDrawer.Content p={2.5} gap={2.5} display={'flex'} flexDirection={'column'}>
            <DefaultAlertText />
            <DragWrapper drag={isDragActive} {...getRootProps()}>
              <DDFileInputWrapper repeatFile={repeatFile} uploadStatus={uploadStatus} />
              <DDFileInput inputProps={{ ...getInputProps() }} />
            </DragWrapper>
            <Button
              startIcon={<Download />}
              sx={{ color: (t) => t.palette.primary.main }}
              onClick={getTemplateClickHandler}>
              Скачать форму файла
            </Button>
            <BtnGroupWrapper
              uploadStatus={uploadStatus}
              isLoading={uploadBdrFileResponse.isLoading}
              DISABLE_SUBMIT_BTN_STATUSES={DISABLE_SUBMIT_BTN_STATUSES}
              closeDrawer={closeDrawerWithConfirmHandler}
              onRepeatFile={repeatFile}
              cancelSendFile={localCancelSendFile}
              onSend={submitFormClickHandler}
            />
          </MyDrawer.Content>
        </FormProvider>
      </MyDrawer.Wrapper>
    </Fragment>
  )
})
